import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/contact-form.tsx";

function ContactPage() {
  return (
    <>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Contact"
      />
      <Layout
        headerStyles="text-4xl font-bold leading-none tracking-widest text-green-600 md:text-5xl lg:text-6xl"
        headerTitle="Contact Us"
        mainLayout="h-full bg-white"
      >
        <section className="relative h-full">
          <div className="absolute inset-0 ">
            <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
          </div>
          <div className="relative h-full mx-auto max-w-7xl lg:grid lg:grid-cols-5">
            <div className="px-4 py-16 bg-gray-50 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div className="max-w-lg mx-auto">
                <h2 className="text-2xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                  Get in touch
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                  Call or email us today to schedule a no-cost introductory
                  meeting.
                </p>
                <dl className="mt-6 text-base leading-6 text-gray-500">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="ml-3">(346) 217-5882</span>
                  </dd>

                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <svg
                        className="flex-shrink-0 w-6 h-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="ml-3">info@catalinaadvisors.com</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="px-4 py-16 bg-white sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div className="max-w-lg mx-auto lg:max-w-none">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}

export default ContactPage;
