import React from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

type postDataResult<T> = {
  success: boolean;
  errors?: { [P in keyof T]?: string[] };
};

type FormData = {
  name: string;
  email: string;
  phone: string;
  company: string;
  message: string;
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),
  phone: yup.string().matches(phoneRegExp, "Invalid phone number"),
  company: yup.string().required("Required"),
  message: yup.string().required("Required"),
});

const postData = ({ name }: FormData): Promise<postDataResult<FormData>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log(`${name} saved.`);
      resolve({ success: true });
    }, 2500);
  });
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

export default function ContactForm() {
  const { register, handleSubmit, errors, reset, formState } = useForm<
    FormData
  >({
    resolver: yupResolver(schema),
  });
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(
    false
  );

  const submitForm = async (data: FormData) => {
    console.log("Submission starting", data);

    const result = await postData(data);

    fetch("/?no-cache=1", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": "contact",
        ...data,
      }),
    });

    console.log("Submitting complete", result.success);

    setIsSuccessfullySubmitted(result.success);

    reset();
  };

  return (
    <form
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-input"
      className="grid grid-cols-6 gap-6"
      onSubmit={handleSubmit(submitForm)}
    >
      <input
        ref={register({
          required: true,
        })}
        className=""
        type="hidden"
        name="contact"
        value="contact"
      />

      <div className="col-span-6 md:col-span-3">
        <div className="flex flex-wrap items-start w-full h-full">
          <label htmlFor="name" className="sr-only ">
            Name
          </label>

          <div className="relative w-full rounded-md shadow-sm ">
            <input
              ref={register({
                required: true,
              })}
              id="name"
              className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input"
              name="name"
              placeholder="Full Name"
            />
          </div>
          <div className="flex justify-start w-full px-4 pt-2 text-xs text-red-500 ">
            {errors.name?.message}
          </div>
        </div>
      </div>
      <div className="col-span-6 md:col-span-3">
        <div className="flex flex-wrap items-start w-full h-full">
          <label htmlFor="email" className="sr-only">
            Email
          </label>

          <div className="relative w-full rounded-md shadow-sm ">
            <input
              ref={register({
                required: true,
              })}
              id="email"
              className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="flex justify-start w-full px-4 pt-2 text-xs text-red-500 ">
            {errors.email?.message}
          </div>
        </div>
      </div>
      <div className="col-span-6 md:col-span-3 ">
        <div className="flex flex-wrap items-start w-full h-full">
          <label htmlFor="email" className="sr-only">
            Phone
          </label>

          <div className="relative w-full rounded-md shadow-sm">
            <input
              ref={register({
                required: true,
              })}
              id="phone"
              className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input"
              name="phone"
              placeholder="Phone"
            />
          </div>
          <div className="flex justify-start w-full px-4 pt-2 text-xs text-red-500 ">
            {errors.phone?.message}
          </div>
        </div>
      </div>
      <div className="col-span-6 md:col-span-3 ">
        <label htmlFor="company" className="sr-only">
          Company
        </label>
        <div className="flex flex-wrap items-start w-full h-full">
          <div className="relative w-full rounded-md shadow-sm">
            <input
              ref={register({
                required: true,
              })}
              id="company"
              className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input"
              name="company"
              placeholder="Company"
            />
          </div>
          <div className="flex justify-start w-full px-4 pt-2 text-xs text-red-500 ">
            {errors.company?.message}
          </div>
        </div>
      </div>

      <div className="col-span-6">
        <div className="flex flex-wrap items-center w-full h-full">
          <label htmlFor="message" className="sr-only">
            Message
          </label>

          <div className="relative w-full rounded-md shadow-sm">
            <textarea
              ref={register({
                required: true,
              })}
              id="message"
              className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input"
              rows={4}
              name="message"
              placeholder="Message"
            />
          </div>
          <div className="flex justify-start w-full px-4 pt-2 text-xs text-red-500 ">
            {errors.message?.message}
          </div>
        </div>
      </div>

      <div className="inline-flex items-start max-w-lg col-span-6 space-x-4">
        <button
          className="inline-flex items-start justify-center w-24 px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700"
          type="submit"
          disabled={formState.isSubmitting || isSuccessfullySubmitted}
        >
          Submit
        </button>

        <motion.svg
          initial={"hidden"}
          animate={formState.isSubmitting ? "visible" : "hidden"}
          variants={variants}
          className="w-5 h-5 mr-3 -ml-1 text-black animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </motion.svg>

        <motion.div
          initial={"hidden"}
          animate={isSuccessfullySubmitted ? "visible" : "hidden"}
          variants={variants}
          className="success"
        >
          Form submitted successfully. Thank You!
        </motion.div>
      </div>
    </form>
  );
}
